import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";

const TSPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Container>
					<Row className="py-5">
						<Col>
							<h1 className="pb-3">
								Thank you for submitting your shortlist to Flex.
							</h1>
							<p>
								You will receive an email with a link to your list and we will
								get back to you asap to confirm the viewing times and dates.  
							</p>
							<Link to="/">
								<p> &#8592; Home</p>
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default TSPage;
